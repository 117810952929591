import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  Avatar as UIAvatar,
  ChatContainer,
  Message,
  MessageInput,
  MessageList,
} from '@chatscope/chat-ui-kit-react';
import { Box, Typography } from '@mui/material';
import Avatar from 'features/Avatar/Avatar';
import { useSession } from 'next-auth/react';
import { useEffect, useRef, useState } from 'react';
import { imageTypeAlias } from 'shared/constants';
import {
  useGetChatUsersQuery,
  useGetMessagesQuery,
  useLazyDelUnReadMessagesQuery,
  useLazyPostMessageQuery,
} from 'slices/ChatSlice';
import Users from '../Users';
import { DateRange } from 'shared/helper/dateRange';

export const UI = ({ title, chat, socketMessages, hide, userId }: any) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { data: sessionData } = useSession();
  const { data: messages } = useGetMessagesQuery(chat.id ?? null);

  const { data: chatUsers } = useGetChatUsersQuery({ chatId: chat.id });
  const [postMessage] = useLazyPostMessageQuery();
  const [delMessages] = useLazyDelUnReadMessagesQuery();
  const [text, setText] = useState('');
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateRemainingHeight = () => {
      if (divRef.current) {
        const blockTop = divRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        setHeight(windowHeight - blockTop - 10);
      }
    };
    // Вызовите функцию при монтировании компонента
    updateRemainingHeight();
    window.addEventListener('resize', updateRemainingHeight);
    // Удалите обработчик, когда компонент будет размонтирован
    return () => {
      window.removeEventListener('resize', updateRemainingHeight);
    };
  }, [title]);

  useEffect(() => {
    if (messages?.length && chatUsers?.length) {
      delMessages({
        chatUserId: chatUsers?.find(
          (item: any) => item.userId === (sessionData as any)?.user.id
        ).id,
      });
    }
  }, [messages, chatUsers]);

  const sendMessage = () => {
    postMessage({
      text: text,
      chatId: chat?.id,
      userId: (sessionData as any)?.user.id,
    });
    setText('');
  };

  return (
    <Box
      sx={{
        marginBottom: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
        }}
      >
        <Typography
          variant="h5"
          fontFamily={'Winston SemiBold'}
          sx={{
            maxWidth: '70%', // Максимальная ширина для текста
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </Typography>
        {chatUsers ? (
          <Users
            hide={hide}
            userId={userId}
            chatId={chat.id}
            chatUsers={chatUsers}
          />
        ) : (
          <Box sx={{ height: '52px' }}></Box>
        )}
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box
          ref={divRef}
          sx={{
            height: `${height}px`,
            marginTop: 1,
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            borderRadius: '12px',
            border: '1px solid #F4F4F5',
          }}
        >
          <ChatContainer style={{ width: '100%', opacity: '0.9' }}>
            <MessageList
              style={{
                // background: 'url("/images/chat.jpg")',
                // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                background: 'rgba(220, 220 ,220, 0.2)',
                backgroundBlendMode: 'overlay',
                padding: '5px',
              }}
            >
              {messages && chatUsers?.length
                ? [...messages, ...(socketMessages ? socketMessages : [])]?.map(
                    (item: any) => {
                      return (
                        <Message
                          key={item.id}
                          model={{
                            message: item.text,
                            sentTime: 'just now',
                            sender: 'Заказчик',
                            position: 'normal',
                            direction:
                              item.userId !== (sessionData as any).user.id
                                ? 'incoming'
                                : 'outgoing',
                          }}
                        >
                          <Message.Header>
                            <Box
                              sx={{
                                color: 'black',
                                textAlign: 'end',
                                marginTop: 1,
                              }}
                            >
                              {
                                chatUsers?.find(
                                  (user: any) => user.user.id === item.userId
                                )?.user?.nameAbbr
                              }
                            </Box>
                          </Message.Header>
                          <UIAvatar>
                            <Avatar
                              userId={item?.userId}
                              imageType={imageTypeAlias.userAvatar}
                            />
                          </UIAvatar>

                          <Message.Footer>
                            {DateRange(new Date(item?.ins) as Date) as string}
                          </Message.Footer>
                        </Message>
                      );
                    }
                  )
                : null}
            </MessageList>
            <MessageInput
              onSend={sendMessage}
              value={text}
              onChange={(value: any) => setText(value)}
              placeholder="Введите сообщение"
            />
          </ChatContainer>
        </Box>
      </Box>
    </Box>
  );
};
