import React from 'react';
import Link from 'next/link';

import {
  Box,
  Typography,
  Toolbar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { UserAvatarProvider } from 'features/UserAvatar/useUserAvatar';
import UserAvatar from 'features/UserAvatar/UserAvatar';
import { paths } from 'shared/routing/paths';
import { UserType } from 'types/User';
import { DashboardProps } from './type';
import { Searchbar } from 'features/Searchbar';
import { Footer } from '../Footer';
import Notification from 'features/Notification';
import Chats from 'models/Chats';
import SignModal from 'features/SignModal';
import {
  mainBox,
  appBarStyle,
  boxMenu,
  toolbarBox,
  toolbarStyle,
  menuText,
  arrowStyle,
  menuStyle,
  boxUserBtns,
  boxUserStyle,
  nameOrPhoneStyle,
  menuAppbarStyle,
  menuItem,
  accountIcon,
  menuItemBox,
  logoutIcon,
  logoutText,
  loginModal,
  drawerStyle,
  drawerHeaderStyle,
  podryadchickText,
  chevronLeftStyle,
  drawerHeaderStyleSecond,
  iconButtonStyle,
  listStyle,
  dividerStyle,
  listItemStyle,
  boxContainer,
  boxContainerIcon,
  listItemTextStyle,
  dividerStyleSecond,
  mainBoxComponent,
  boxSubtitle,
} from './styles.ts/dashboardStyles';
import useHelperDashboard from './hooks/useHelperDashboard';
import { CityDrawer } from 'models/Map';
import { Map } from '@mui/icons-material';

export const UI = ({ children }: DashboardProps) => {
  const {
    AppBar,
    Drawer,
    DrawerHeader,
    open,
    session,
    user,
    isActiveTopBar,
    isActiveTopBarLine,
    handleOpenElMenu,
    showMenu,
    anchorElMenu,
    handleCloseElMenu,
    handleOpenUserMenu,
    nameOrPhone,
    anchorElUser,
    handleCloseUserMenu,
    removeToken,
    sessionData,
    router,
    handleDrawerClose,
    handleDrawerOpen,
    isActiveSideBar,
  } = useHelperDashboard();

  return (
    <UserAvatarProvider>
      <Box sx={mainBox}>
        <AppBar position="fixed" open={open} sx={appBarStyle(session)}>
          <Toolbar sx={toolbarStyle}>
            <Box sx={toolbarBox}>
              {paths.appbar(user as UserType).map((item) =>
                item ? (
                  <Link key={item?.title} href={item?.href}>
                    <MenuItem
                      sx={{
                        fontSize: 14,
                        fontFamily: 'Winston Regular',
                        fontWeight: 900,
                        color: isActiveTopBar(item),
                        borderBottom: isActiveTopBarLine(item),
                        ':hover': { opacity: 0.85 },
                      }}
                    >
                      {item?.title}
                    </MenuItem>
                  </Link>
                ) : null
              )}
            </Box>
            <Box sx={boxMenu}>
              <Box>
                <IconButton disableRipple onClick={handleOpenElMenu}>
                  <Box display={'flex'}>
                    <Typography sx={menuText}>Меню</Typography>
                    <KeyboardArrowRightIcon sx={arrowStyle(showMenu)} />
                  </Box>
                </IconButton>
                <Menu
                  sx={menuStyle}
                  id="menu"
                  anchorEl={anchorElMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElMenu)}
                  onClose={handleCloseElMenu}
                >
                  {paths.appbar(user as UserType).map((item) =>
                    item ? (
                      <MenuItem
                        key={item?.title}
                        sx={{
                          fontSize: 14,
                          fontFamily: 'Winston Regular',
                          color: isActiveTopBar(item),
                        }}
                        component="a"
                        href={item?.href}
                      >
                        {item?.title}
                      </MenuItem>
                    ) : null
                  )}
                </Menu>
              </Box>
            </Box>
            <CityDrawer />
            <Link href="/map">
              <Map sx={{ color: 'white' }} />
            </Link>
            {/* <Searchbar slice={4} width="300px" pl={1} pr={1} /> */}
            <Box sx={boxUserBtns}>
              {user ? <Notification /> : null}
              {user ? <Chats /> : null}
            </Box>
            {user ? (
              <Box sx={boxUserStyle}>
                <IconButton disableRipple onClick={handleOpenUserMenu}>
                  <UserAvatar userId={user?.id} />
                  <Typography sx={nameOrPhoneStyle}>{nameOrPhone}</Typography>
                </IconButton>
                <Menu
                  sx={menuAppbarStyle}
                  disableScrollLock={true}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Link href="/account">
                    <MenuItem onClick={handleCloseUserMenu} sx={menuItem}>
                      <AccountCircleIcon sx={accountIcon} />
                      Личный кабинет
                    </MenuItem>
                  </Link>
                  {/* <Link href="/myleads/incoming">
                    <MenuItem onClick={handleCloseUserMenu} sx={menuItem}>
                      <ListAltIcon sx={accountIcon} />
                      Мои заявки
                    </MenuItem>
                  </Link> */}

                  {
                    <Box sx={menuItemBox}>
                      <MenuItem>{user ? <Notification /> : null}</MenuItem>
                      <MenuItem>{user ? <Chats /> : null}</MenuItem>
                    </Box>
                  }

                  <MenuItem onClick={removeToken}>
                    <LogoutIcon sx={logoutIcon} />
                    <Typography sx={logoutText} textAlign="center">
                      Выйти
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box>
                <SignModal title={'Войти'} sx={loginModal} />
                {/* <Button
                  sx={{
                    color: theme.palette.secondary.light,
                    fontFamily: 'Winston Regular',
                    fontWeight: 600,
                    fontSize: 17,
                    '@media (max-width: 958px)': {
                      fontSize: 15,
                    },
                    ':hover': {
                      color: theme.palette.secondary.light,
                    },
                  }}
                  onClick={() => router.push('/auth/signin')}
                >
                  Войти
                </Button> */}
              </Box>
            )}
          </Toolbar>
        </AppBar>
        {sessionData ? (
          <Drawer sx={drawerStyle} variant="permanent" open={open}>
            {open ? (
              <DrawerHeader sx={drawerHeaderStyle}>
                <Typography
                  sx={podryadchickText}
                  onClick={() => router.push('/')}
                  variant="h6"
                  noWrap
                  component="div"
                >
                  Подрядчик.рф
                </Typography>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon sx={chevronLeftStyle} />
                </IconButton>
              </DrawerHeader>
            ) : (
              <DrawerHeader sx={drawerHeaderStyleSecond}>
                <IconButton onClick={handleDrawerOpen} sx={iconButtonStyle}>
                  П
                </IconButton>
              </DrawerHeader>
            )}
            <List sx={listStyle}>
              <Divider sx={dividerStyle} />
              {paths.sidebar().map((item, index) =>
                item.title ? (
                  <ListItemButton
                    key={item.title}
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      alignItems: 'center',
                      background: isActiveSideBar(item),
                      // padding: '10px',
                      px: 2.5,
                      width: '100%',
                    }}
                    // onClick={() => (item.href ? router.push(item.href) : '')}
                    href={item.href ? `${item.href}` : ''}
                  >
                    <ListItemIcon sx={listItemStyle(open)}>
                      <Box sx={boxContainer}>
                        <Box sx={boxContainerIcon(open)}>{item.icon}</Box>
                        {!open && <Box sx={boxSubtitle}>{item.subTitle}</Box>}
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      sx={listItemTextStyle(open)}
                    />
                  </ListItemButton>
                ) : (
                  <Divider key={index} sx={dividerStyleSecond} />
                )
              )}
            </List>
          </Drawer>
        ) : null}
        <Box component="main" sx={mainBoxComponent}>
          {children}
        </Box>
      </Box>
      <Box mt={4}>
        <Footer />
      </Box>
    </UserAvatarProvider>
  );
};
