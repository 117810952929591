import React from 'react';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Modal,
  Box,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useModel from './model';

interface UIProps {
  organizationId: number;
  chatId: number;
  chatUsers: any;
}

interface Employee {
  id: string;
  userId: string;
  user: {
    name: string;
  };
}

export const UI: React.FC<UIProps> = ({
  organizationId,
  chatUsers,
  chatId,
}) => {
  const {
    open,
    departments,
    handleOpen,
    handleClose,
    selectedEmployees,
    selectedDepartments,
    expandedDepartments,
    handleToggleDepartment,
    handleDepartmentChange,
    handleEmployeeChange,
    onSubmit,
  } = useModel(organizationId, chatUsers, chatId);

  const renderEmployeeCheckboxes = (
    departmentId: string,
    employees: Employee[]
  ) => {
    return employees?.map((employee) => (
      <FormControlLabel
        key={employee.id}
        sx={{ ml: 2 }}
        control={
          <Checkbox
            checked={selectedEmployees[employee.userId] || false}
            onChange={(e) =>
              handleEmployeeChange(
                departmentId,
                employee.userId,
                e.target.checked
              )
            }
          />
        }
        label={employee.user.name}
      />
    ));
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        +
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{ height: '80%', overflowY: 'scroll' }}>
            {departments
              ?.filter((department: any) => department?.employees?.length > 0)
              ?.map((department: any) => (
                <Box key={department.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    {expandedDepartments[department.id] ? (
                      <ExpandMoreIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          handleToggleDepartment(department.id);
                        }}
                      />
                    ) : (
                      <ChevronRightIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          handleToggleDepartment(department.id);
                        }}
                      />
                    )}
                    <Checkbox
                      checked={selectedDepartments[department.id] || false}
                      onChange={(e) =>
                        handleDepartmentChange(department.id, e.target.checked)
                      }
                      onClick={(event) => event.stopPropagation()}
                    />
                    <label
                      onClick={() => handleToggleDepartment(department.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      {department.name}
                    </label>
                  </Box>
                  <Collapse in={expandedDepartments[department.id] || false}>
                    <Box sx={{ marginLeft: 3, marginBottom: 1 }}>
                      {renderEmployeeCheckboxes(
                        department.id,
                        department.employees
                      )}
                    </Box>
                  </Collapse>
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ marginRight: '10px' }}
            >
              Отмена
            </Button>
            <Button onClick={onSubmit} variant="contained" color="primary">
              Сохранить
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: '50vh',
  background: '#fff',
  border: '2px solid #000',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
} as const;
