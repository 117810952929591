import theme from 'shared/theme';

export const mainBox = { display: 'flex' };
export const appBarStyle = (session: any) => ({
  background: theme.palette.grey[900],
  zIndex: 999,
  paddingLeft: session.data && 8,
});
export const toolbarStyle = {
  '@media (max-width: 600px)': { p: 0 },
  color: theme.palette.grey[900],
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 5,
};
export const toolbarBox = {
  flexGrow: 1,
  display: 'none',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
};
export const boxMenu = {
  flexGrow: 1,
  display: 'none',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
  },
};
export const menuText = {
  color: theme.palette.secondary.light,
  fontFamily: 'Winston Regular',
  fontWeight: 600,
  fontSize: 15,
};
export const arrowStyle = (showMenu: boolean) => ({
  fontSize: 20,
  transition: 'transform 0.3s ease',
  transform: showMenu ? 'rotate(90deg)' : 'rotate(0)',
  color: theme.palette.secondary.light,
});
export const menuStyle = {
  mt: '45px',
  '& .MuiMenu-paper': {
    backgroundColor: theme.palette.grey[900],
  },
};
export const boxUserBtns = {
  p: 1,
  '@media (max-width: 900px)': { fontSize: 12 },
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
};
export const boxUserStyle = {
  minWidth: 60,
};
export const nameOrPhoneStyle = {
  width: 150,
  wordWrap: 'break-word',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: 'Winston Medium',
  fontWeight: 600,
  fontSize: 15,
  color: theme.palette.secondary.light,
  [theme.breakpoints.down('md')]: {
    fontSize: 12,
    width: 115,
  },
  padding: '0 5px',
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
};
export const menuAppbarStyle = {
  mt: '45px',
  '& .MuiMenu-paper': {
    backgroundColor: theme.palette.grey[900],
  },
};
export const menuItem = { color: theme.palette.secondary.light };
export const accountIcon = {
  mr: 1,
  color: 'white',
};
export const menuItemBox = {
  '@media (max-width: 900px)': { fontSize: 12 },
  display: 'none',

  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexDirection: 'column',
  },
};
export const logoutIcon = {
  color: theme.palette.error.light,
  mr: 1,
};
export const logoutText = {
  color: theme.palette.error.light,
  fontSize: 16,
};
export const loginModal = {
  color: theme.palette.secondary.light,
  fontFamily: 'Winston Regular',
  fontWeight: 600,
  fontSize: 17,
  '@media (max-width: 958px)': {
    fontSize: 15,
  },
  ':hover': {
    color: theme.palette.secondary.light,
  },
};
export const drawerStyle = {
  '& .MuiDrawer-paper': {
    backgroundColor: '#18181B',
  },
};
export const drawerHeaderStyle = {
  background: '#18181B',
  display: 'flex',
  padding: '20px 16px',
  height: '64px',
};
export const podryadchickText = {
  marginRight: 1,
  cursor: 'pointer',
  color: theme.palette.secondary.main,
};
export const chevronLeftStyle = { color: theme.palette.secondary.main };
export const drawerHeaderStyleSecond = {
  background: '#18181B',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px 16px',
  height: '64px',
};
export const iconButtonStyle = {
  background: theme.palette.grey[800],
  width: '40px',
  borderRadius: 3,
  height: 40,
  color: theme.palette.secondary.main,
  fontFamily: 'Winston SemiBold',
  '&:hover': {
    background: theme.palette.grey[600],
  },
};
export const listStyle = { height: '100vh', background: '#18181B', padding: 0 };
export const dividerStyle = { background: '#52525B', margin: '0 10px' };
export const listItemStyle = (open: boolean) => ({
  display: 'flex',
  minWidth: 0,
  mr: open ? 3 : 'auto',
  mb: !open ? 3 : 'auto',
  '@media (max-width: 650px)': {
    mb: !open ? 1 : 'auto',
    width: '30px',
    height: '30px',
  },
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  color: 'black',
});
export const boxContainer = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};
export const boxContainerIcon = (open: boolean) => ({ mt: open ? 'auto' : 2 });
export const listItemTextStyle = (open: boolean) => ({ opacity: open ? 1 : 0 });
export const boxSubtitle = {
  fontSize: 9,
  color: '#fff',
  padding: '5px',
  borderRadius: '8px',
  '@media (max-width: 650px)': {
    display: 'none',
    padding: 0,
    fontSize: 0,
  },
};
export const dividerStyleSecond = { background: '#52525B', margin: '0 10px' };
export const mainBoxComponent = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: 7,
  width: '100%',
  overflow: 'auto',
  padding: 1.5,
  minHeight: 'calc(100vh - 440px)',
  [theme.breakpoints.down('sm')]: { mt: 5.5 },
};
