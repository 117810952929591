import React, { useContext, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import moment from 'moment';
import theme from 'shared/theme';
import Link from 'next/link';
import { PageContext } from 'store/pagecontext';
import WebApi from 'services/index';

export const UI = () => {
  const {
    lead,
    statuses,
  }: {
    lead: any;
    statuses: any;
  } = useContext(PageContext);
  const [id, setId] = useState(lead.leadStatusId);

  const onChange = async (leadStatusId: number) => {
    const { error, data } = await WebApi().Leads.update({
      id: lead.id,
      leadStatusId,
    });

    if (!error) {
      setId(leadStatusId);
    }
  };
  return (
    <Box>
      <Box>
        <Link
          target="_blank"
          href={`/marketplace/advert/${lead?.category?.alias}/${lead?.advertId}`}
        >
          <Typography variant="h5" component={'a'} sx={{ width: '100%' }}>
            {lead?.advert?.title}
          </Typography>
        </Link>
      </Box>
      <Typography variant="h5">Данные по заявке</Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '4px',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ width: '33%' }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
          >
            Заказчик
          </Typography>
          <Link target="_blank" href={`/companies/${lead?.organization?.id}`}>
            <Typography variant="body2">{lead?.organization?.title}</Typography>
          </Link>
        </Box>
        <Box sx={{ width: '33%' }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
          >
            Поставщик
          </Typography>
          <Link
            target="_blank"
            href={`/companies/${
              lead?.advert?.organization?.id
                ? lead?.advert?.organization?.id
                : lead?.tender?.organization?.id
            }`}
          >
            <Typography variant="body2">
              {lead?.advert?.organization?.name ??
                lead?.tender?.organization?.name}
            </Typography>
          </Link>
        </Box>
        <Box sx={{ width: '33%' }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
          >
            Стоимость
          </Typography>
          <Typography variant="body2">
            {new Intl.NumberFormat('ru-RU').format(
              lead?.advert?.price ?? lead?.data?.price
            )}{' '}
            {lead?.currency?.sym ?? lead?.data.price}
          </Typography>
        </Box>
        {lead?.advert?.ins ? (
          <Box sx={{ width: '33%' }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Дата создания
            </Typography>
            <Typography variant="body2">
              {moment(lead?.advert?.ins).format('DD.MM.YYYY')}
            </Typography>
          </Box>
        ) : null}
        <Box sx={{ width: '33%', pt: 1, pb: 1 }}>
          <FormControl>
            <InputLabel id="statusess">Статусы</InputLabel>
            <Select
              id="statusess_"
              value={id}
              labelId="statusess"
              label="Статусы"
              onChange={(e) => onChange(e.target.value)}
              sx={{
                minWidth: '120px',
                height: '36px',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              {statuses?.map((status: any) => (
                <MenuItem key={status.id} value={status.id}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {lead?.data?.rentalDuration ? (
          <Box sx={{ width: '33%' }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Длительность аренды
            </Typography>
            <Typography variant="body2">
              {lead?.data?.rentalDuration}
            </Typography>
          </Box>
        ) : null}
        {lead?.data?.workStart ? (
          <Box sx={{ width: '33%' }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Дата и время начала работ
            </Typography>
            <Typography variant="body2">
              {moment(lead?.data?.workStart).format('DD.MM.YYYY HH:MM')}
            </Typography>
          </Box>
        ) : null}
        {lead?.data?.measure ? (
          <Box sx={{ width: '33%' }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Единица измерения
            </Typography>
            <Typography variant="body2">{lead?.data?.measure}</Typography>
          </Box>
        ) : null}
        {lead?.data?.quantity ? (
          <Box sx={{ width: '33%' }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Объем/кол-во
            </Typography>
            <Typography variant="body2">{lead?.data?.quantity}</Typography>
          </Box>
        ) : null}
        {lead?.data?.requirements ? (
          <Box sx={{ width: '33%' }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Описание задачи
            </Typography>
            <Typography variant="body2">{lead?.data?.requirements}</Typography>
          </Box>
        ) : null}
        {lead?.data?.startDate ? (
          <Box sx={{ width: '33%' }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Дата начала
            </Typography>
            <Typography variant="body2">
              {moment(lead?.data?.startDate).format('DD.MM.YYYY HH:MM')}
            </Typography>
          </Box>
        ) : null}
        {lead?.data?.endDate ? (
          <Box sx={{ width: '33%' }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Дата окончания
            </Typography>
            <Typography variant="body2">
              {moment(lead?.data?.endDate).format('DD.MM.YYYY HH:MM')}
            </Typography>
          </Box>
        ) : null}{' '}
        {lead?.data?.description ? (
          <Box sx={{ width: '33%' }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Описание задачи
            </Typography>
            <Typography variant="body2">{lead?.data?.description}</Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
