import { Box } from '@mui/material';
import { useSession } from 'next-auth/react';

import { useEffect, useState } from 'react';
import WebApi from 'services/index';
import theme from 'shared/theme';

export const UI = ({
  objectFit,
  companyId,
  advertId,
  width,
  height,
  round,
  imageType,
  maxWidth,
  maxHeight,
}: any) => {
  const { data: sessionData } = useSession();
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  useEffect(() => {
    if (!avatarUrl) {
      const getImg = async () => {
        const args = {
          ...(companyId ? { organizationId: companyId } : {}),
          ...(advertId ? { advertId } : {}),
          ...(imageType ? { imageType } : {}),
        };
        const isArgs = Object.keys(args).length;
        if (isArgs) {
          const { data } = await WebApi(sessionData).Image.getImage(args);
          setAvatarUrl(data?.length ? data[data.length - 1].filepath : '');
        }
      };
      getImg();
    }
  }, [advertId, avatarUrl, companyId]);
  return (
    <Box
      component="img"
      sx={{
        height: height ?? 233,
        width: width ?? 350,
        borderRadius: round ?? '0',
        maxHeight: maxHeight ?? {
          lg: 230,
          sm: 140,
          md: 140,
          [theme.breakpoints.down('sm')]: { maxHeight: 65 },
        },
        objectFit: objectFit ? objectFit : 'fill',
        maxWidth: maxWidth ?? {
          lg: 250,
          sm: 190,
          md: 190,
          [theme.breakpoints.down('sm')]: { maxWidth: 70 },
        },
      }}
      alt="The picture"
      src={
        avatarUrl
          ? `${process.env.NEXT_PUBLIC_IMG}/${avatarUrl}`
          : 'https://react.semantic-ui.com/images/wireframe/image.png'
      }
    />
  );
};
