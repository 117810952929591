import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  Card,
  Button,
  Typography,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';

import { schema, defaultValue } from './model';
import style from './style.module.scss';
import theme from 'shared/theme';

export const UI = ({
  handleClose,
  onOpenDrawer,
  onChangeStep,
}: {
  handleClose?: Function;
  onOpenDrawer?: Function;
  onChangeStep?: Function;
}) => {
  const [successSignIn, setSuccessSignIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPas] = useState(false);
  const router = useRouter();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: defaultValue(),
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (successSignIn) {
      setTimeout(() => {
        router.query.code
          ? router.push(`/go?code=${router.query.code}`)
          : router.push('/');
      }, 1300);
    }
  }, [successSignIn]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await signIn('local-login', {
        ...data,
        redirect: false,
      });
      if (response && response.ok) {
        setSuccessSignIn(true);
        toast.success('Вы успешно авторизовались');
        if (onOpenDrawer && handleClose) {
          handleClose();
          onOpenDrawer();
        }
      } else if (response && response.status === 401) {
        setLoading(false);
        toast.error('Неверный логин или пароль');
      }
    } catch (err) {
      setLoading(false);
      toast.error('Ошибка авторизации');
    }
  };

  const togglePassword = () => {
    setShowPas((prevShowPass) => !prevShowPass);
  };

  return (
    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          maxWidth: 550,
          padding: '96px',
          [theme.breakpoints.down('sm')]: { maxWidth: 300, padding: 3 },
        }}
      >
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          Вход
        </Typography>
        <Box mt={3} />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <InputMask
              mask="+7(\999)999-99-99"
              value={field.value}
              onChange={field.onChange}
            >
              <TextField
                fullWidth
                size="small"
                label="Номер телефона"
                placeholder="Введите номер телефона"
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            </InputMask>
          )}
        />
        <Box mt={3} />

        <FormControl sx={{ width: '100%' }} variant="outlined">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                type={showPass ? 'text' : 'password'}
                {...field}
                fullWidth
                size="small"
                label="Пароль"
                placeholder="Введите пароль"
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePassword}>
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <Box sx={{ display: 'flex', marginTop: 1, justifyContent: 'center' }}>
          <Typography sx={{ opacity: 0.8 }}>
            {onChangeStep ? (
              <Button
                className={style.link}
                sx={{ background: '#fff', padding: 0 }}
                onClick={() => onChangeStep('3')}
              >
                {' '}
                Востановить пароль
              </Button>
            ) : (
              <Link className={style.link} href="/auth/resetPass">
                Востановить пароль
              </Link>
            )}
          </Typography>
        </Box>
        <Box mt={3} />
        <Box mb={3}>
          <Box sx={{ fontSize: 14, textAlign: 'center' }}>
            При входе, Вы принимаете условия{' '}
            <Link className={style.policy} href="/privacy/agreement">
              <Typography>Пользовательского соглашения</Typography>
            </Link>{' '}
            и{' '}
            <Link className={style.policy} href="/privacy/policy">
              <Typography>Политики конфиденциальности</Typography>
            </Link>
          </Box>
        </Box>
        <Button
          type="submit"
          disabled={loading}
          variant="contained"
          sx={{ width: '100%' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Войти'}
        </Button>
        <Box
          mt={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Typography sx={{ opacity: 0.8 }}>Нет аккаунта?</Typography>
          {onChangeStep ? (
            <Button
              className={style.link}
              sx={{ background: '#fff', padding: 0 }}
              onClick={() => onChangeStep('2')}
            >
              Зарегистрироваться
            </Button>
          ) : (
            <Link className={style.link} href="/auth/signup">
              Зарегистрироваться
            </Link>
          )}
        </Box>
        <Box mb={2}>
          {!handleClose ? (
            <Link className={style.minilink} href="/">
              Вернуться на главную
            </Link>
          ) : (
            <Button
              className={style.minilink}
              sx={{ background: '#fff', p: 0, fontSize: 16 }}
              onClick={() => handleClose()}
            >
              Вернуться на главную
            </Button>
          )}
        </Box>
      </Card>
    </form>
  );
};
